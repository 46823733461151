<template>
  <div class="reserveView content-index">
    <div style="padding-bottom:15px">
      <el-page-header
          style="padding:10px"
          @back="$router.go(-1)"
          content="预约"
      ></el-page-header>
    </div>
    <div class="headerView">
      <div class="headerItem">
        订单号
      </div>
      <div class="headerItem">
        联系人
      </div>
      <div class="headerItem">
        电话
      </div>
      <div class="headerItem">
        地址
      </div>
      <div class="headerItem">
        件数
      </div>
      <div class="headerItem">
        下单方式
      </div>
      <div class="headerItem">
        预约上门
      </div>
      <div class="headerItem" style="flex: 2;">
        下单时间
      </div>
      <div class="headerItem">
        付款状态
      </div>
      <div class="headerItem">
        订单金额
      </div>
      <div class="headerItem" style="flex: 2;">
        操作
      </div>
    </div>
    <div
      class="listView"
      style="height: 539px;"
      v-infinite-scroll="loadOrderArr"
      infinite-scroll-distance="10"
      infinite-scroll-disabled="disableLoadOrderArr"
      v-loading="cancelOrderLoading"
    >
      <div class="listItem" v-for="(item, index) in orderArr" :key="index">
        <div class="listItem">{{ item.orderNumber }}</div>
        <div class="listItem">{{ item.userName }}</div>
        <div class="listItem" >{{ item.userPhone }}</div>
        <div class="listItem">{{ item.userAddress }}</div>
        <div class="listItem">{{ item.clothesNum }}</div>
        <div class="listItem">
          <el-tag>
            {{ item.oneClickOrder === 2 ? '一键下单' : '正常下单' }}
          </el-tag>
        </div>
        <div class="listItem">{{ item.logisticsStatus }}</div>
        <div class="listItem" style="flex: 2;">{{ item.orderTime }}</div>
        <div class="listItem">{{ item.payStatus }}</div>
        <div class="listItem" style="color: red;">
          ¥ {{ item.actuallyPaidPrice }}
        </div>
        <div class="listItem" style="flex: 2;">
          <el-button-group>
            <el-button
              type="primary"
              size="mini"
              v-if="
                item.logisticsStatus === '已下单' &&
                item.orderStatus !== '已取消'
              "
              @click="handlerTakeOrder(item)"
            >
              接单
            </el-button>
            <el-button
              type="primary"
              size="mini"
              v-if="item.orderStatus === '待收衣' && item.oneClickOrder === 2"
              @click="handlerGetClothes(item)"
            >
              收衣
            </el-button>
            <el-button
              type="primary"
              size="mini"
              v-if="item.orderStatus === '待收衣' && item.oneClickOrder === 1"
              @click="getClothesOrderDetails(item)"
            >
              收衣
            </el-button>
            <el-button
              type="primary"
              size="mini"
              v-if="item.payStatus === '未支付'"
              @click="cancelOrder(item)"
            >
              取消
            </el-button>
            <el-button
              type="primary"
              size="mini"
              v-else
              @click="handleOrderRefundShow(item)"
            >
              退款
            </el-button>
          </el-button-group>
        </div>
      </div>
      <div v-if="loadingOrderArr" class="moreItem">加载中...</div>
      <div v-if="noMoreOrderArr" class="moreItem">没有更多预约订单哦</div>
    </div>

    <pop-view
      id="refundViewPopView"
      :show="showModal"
      width="900px"
      title="订单退款"
      @cancel="showModal = false"
    >
      <div
        class="contentView"
        v-loading="orderRefundLoading"
        :element-loading-text="orderRefundLoadingVal"
      >
        <div class="topView">
          <div>
            付款方式:
            <span>{{ orderClothesDetail.payWay }}</span>
          </div>
          <div>
            运费:
            <span style="color: red;">{{ orderClothesDetail.fare }}</span>
          </div>
          <div>
            退款金额:
            <span style="color: red;">{{ refundAmount }}元</span>
          </div>
          <div>
            退回路径:
            <span>原路退回</span>
          </div>
        </div>
        <div class="headerView">
          <div>选项</div>
          <div>衣物号</div>
          <div>衣物名称</div>
          <div>颜色</div>
          <div>实付金额</div>
          <div>衣物状态</div>
          <div>退款原因</div>
        </div>
        <div class="listView" style="max-height: 441px;">
          <div
            class="listItem"
            v-for="(item, index) in checkArr"
            :key="index"
            :style="{
              'text-decoration':
                item.clothesStatus === '已退洗' ? 'line-through' : '',
            }"
          >
            <div>
              <el-checkbox
                :value="
                  (checkedClothesList.indexOf(item.clothesNum) === -1
                    ? '-'
                    : '') + item.clothesNum
                "
                :true-label="item.clothesNum"
                :false-label="'-' + item.clothesNum"
                :disabled="true"
                @change="changeCheck"
              ></el-checkbox>
            </div>
            <div>{{ item.outClothesNum }}</div>
            <div>{{ item.clothesName }}</div>
            <div>{{ item.colorName }}</div>
            <div>{{ item.currentPrice }}</div>
            <div>{{ item.clothesStatus }}</div>
            <div>
              <el-input
                placeholder="请填写退款原因"
                :disabled="true"
                v-model="item.refundReason"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="btnView">
          <el-button type="primary" @click="showModal = false">
            取消退款
          </el-button>
          <el-button type="primary" @click="handleOrderRefund">
            确认退款
          </el-button>
        </div>
      </div>
    </pop-view>
  </div>
</template>

<script>
import {
  appletsReserveOrderPage,
  takeOrder,
  getClothesOrderDetails,
  cancelOrder,
  getClothesOrderDetailsForSorting,
  getClothesOrderForRefund,
  orderRefund,
} from '@/api/clothes/order'
import PopView from '@/components/PopView'

export default {
  name: 'index',
  components: {
    PopView,
  },
  data() {
    return {
      orderArr: [],
      loadingOrderArr: false,
      noMoreOrderArr: false,
      currentPage: 1,
      pageSize: 20,
      total: 0, // 总数量
      moreList: [],
      cancelOrderLoading: false,
      orderClothesDetail: {},
      checkedClothesList: [],
      orderRefundLoading: false,
      orderRefundLoadingVal: '加载中...',
      checkArr: [],
      showModal: false,
      refundAmount: 0,
    }
  },
  computed: {
    disableLoadOrderArr() {
      return this.loadingOrderArr || this.noMoreOrderArr
    },
  },
  methods: {
    loadOrderArr() {
      console.log('触发无限滚动')
      let _this = this
      this.loadingOrderArr = true
      // 在这里请求api，获取数据后 push给orderArr
      console.log(_this.currentPage)
      const params = new URLSearchParams()
      params.append('current', this.currentPage)
      params.append('size', this.pageSize)
      appletsReserveOrderPage(params).then((response) => {
        console.log(response)
        _this.moreList = response.data.data.orderList
        _this.total = response.data.data.total
        if (_this.moreList.length === 0) {
          _this.noMoreOrderArr = true
        } else {
          _this.noMoreOrderArr = false
          _this.currentPage++
          _this.moreList.forEach(function (item) {
            _this.orderArr.push(item)
          })
        }
        if (_this.orderArr.length >= _this.total) {
          _this.noMoreOrderArr = true
        }
        _this.loadingOrderArr = false
      })
    },
    // 处理接单
    handlerTakeOrder(item) {
      // 未支付的无法接单
      if (item.oneClickOrder === 1 && item.payStatus === '未支付') {
        this.$message.error('先支付才可以接单哦!')
        return
      }

      this.$confirm('确认接单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.cancelOrderLoading = true
        takeOrder(item.orderNumber).then((response) => {
          console.log(response)
          if (response.data.code === 0) {
            this.cancelOrderLoading = false
            this.$message.success('接单成功,请及时上门!')
            this.currentPage = 1
            this.orderArr = []
            this.loadOrderArr()
          } else {
            this.$message.error(response.data.msg)
          }
        })
      })
    },
    // 快捷收衣
    handlerGetClothes(item) {
      if (item.logisticsStatus === '已下单') {
        this.$message.error('先接单才可以收衣哦!')
        return
      }
      this.$confirm('确认收衣吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        console.log(item)
        let data = {}
        data.flag = 'sorting'
        data.oneClickOrder = 2
        data.orderNumber = item.orderNumber
        data.userId = item.userId
        data.userType = item.userType
        data.phone = item.userPhone
        data.name = item.userName
        data.address = item.userAddress
        data.selectedClothes = []

        this.$router.push({
          path: '/get/types',
          query: { data: JSON.stringify(data) },
        })
      })
    },
    // 分拣
    getClothesOrderDetails(item) {
      if (item.logisticsStatus === '已下单') {
        this.$message.error('先接单才可以收衣哦!')
        return
      }
      this.$confirm('确认收衣吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        getClothesOrderDetailsForSorting(item.orderNumber).then((response) => {
          let orderDetailOption = response.data.data
          console.log(orderDetailOption.orderSource)

          let data = {}
          data.flag = 'sorting'
          data.orderNumber = orderDetailOption.orderNumber
          data.oneClickOrder = 1
          data.userId = orderDetailOption.userId
          data.phone = orderDetailOption.userPhone
          data.name = orderDetailOption.userName
          data.address = orderDetailOption.userAddress
          data.userType = orderDetailOption.userType
          data.oneClickOrder = orderDetailOption.oneClickOrder
          // data.orderSource = orderDetailOption.orderSource
          // 数组
          data.selectedClothes = []
          orderDetailOption.clothesList.forEach((item) => {
            let clothes = {}
            clothes.categoryId = item.categoryId
            clothes.clothesId = item.clothesId
            clothes.clothesImage = item.clothesImage
            clothes.clothesNum = item.clothesNum
            clothes.clothesName = item.clothesName
            clothes.clothingType = item.clothesName
            clothes.washMode = item.clothesServiceName
            clothes.realPrice = item.currentPrice
            clothes.num = item.num
            clothes.remark = item.remark
            let service = {}
            service.serviceId = item.serviceId
            service.serviceName = item.clothesServiceName
            service.sellPrice = item.originalPrice
            service.vipPrice = item.currentPrice
            clothes.service = service

            if (item.brandId) {
              let brand = {}
              brand.brandId = item.brandId
              brand.brandName = item.brandName
              clothes.brand = brand
            }

            if (item.colorId) {
              let color = {}
              color.colorId = item.colorId
              color.colorName = item.colorName
              clothes.color = color
            }

            if (item.blemishes) {
              let blemishList = []
              let blemishs = item.blemishes.split(',')
              let blemishIds = item.blemishIds.split(',')
              for (let i = 0; i < blemishs.length; i++) {
                let blemish = {}
                blemish.blemishId = parseInt(blemishIds[i])
                blemish.blemishName = blemishs[i]
                blemishList.push(blemish)
              }
              clothes.blemish = blemishList
            }

            if (item.effects) {
              let effectsList = []
              let effects = item.effects.split(',')
              let effectIds = item.effectIds.split(',')
              for (let i = 0; i < effects.length; i++) {
                let effect = {}
                effect.effectId = parseInt(effectIds[i])
                effect.effectName = effects[i]
                effectsList.push(effect)
              }
              clothes.effects = effectsList
            }
            clothes.accessory =
              item.clothesAccessoryList === null
                ? []
                : item.clothesAccessoryList
            clothes.photos = item.photos === null ? [] : item.photos
            clothes.couponId = item.couponId
            data.selectedClothes.push(clothes)
          })
          let dataa = JSON.stringify(data)
          this.$router.push({ path: '/get/types', query: { data: dataa } })
        })
      })
    },
    // 取消订单
    cancelOrder(item) {
      this.$prompt('请输入取消原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        console.log(value)
        let data = {
          orderNumber: item.orderNumber,
          cancelOrderReason: value,
        }
        this.cancelOrderLoading = true
        cancelOrder(data)
          .then((response) => {
            if (response.data.code === 0) {
              this.cancelOrderLoading = false
              this.$message({
                message: '取消订单成功',
                type: 'success',
              })
              this.currentPage = 1
              this.orderArr = []
              this.loadOrderArr()
            } else {
              this.cancelOrderLoading = false
              this.$message.error(response.data.msg)
            }
          })
          .catch((res) => {
            this.cancelOrderLoading = false
          })
      })
    },
    // 选中状态
    changeCheck(val) {
      if (val.indexOf('-') === -1) {
        this.checkedClothesList.push(val)
        let refundClothesList = this.checkArr.filter(
          (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1,
        )
        this.refundAmount = refundClothesList.reduce(
          (sum, item) => (sum += item.currentPrice),
          0,
        )
      } else {
        this.checkedClothesList = this.checkedClothesList.filter(
          (i) => i !== val.slice(1),
        )
        let refundClothesList = this.checkArr.filter(
          (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1,
        )
        this.refundAmount = refundClothesList.reduce(
          (sum, item) => (sum += item.currentPrice),
          0,
        )
      }
    },
    // 退款展示
    handleOrderRefundShow(item) {
      this.orderRefundLoading = true
      getClothesOrderForRefund(item.orderNumber).then((response) => {
        this.orderClothesDetail = response.data.data
        this.orderNumber = response.data.data.orderNumber
        this.checkArr =
          this.orderClothesDetail.clothesList &&
          this.orderClothesDetail.clothesList.map((t) => ({
            refundReason: '小程序取消订单',
            ...t,
          }))
        this.checkArr.forEach((item) => {
          this.checkedClothesList.push(item.clothesNum)
        })
        let refundClothesList = this.checkArr.filter(
          (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1,
        )
        this.refundAmount = refundClothesList.reduce(
          (sum, item) => (sum += item.currentPrice),
          this.orderClothesDetail.fare,
        )
        this.orderRefundLoading = false
      })
      this.showModal = true
    },
    // 订单退款
    handleOrderRefund() {
      if (this.checkedClothesList.length === 0) {
        this.$message.error('请选择退款衣物')
        return false
      }
      // 判断是否填写退款原因
      let refundClothesList = this.checkArr.filter(
        (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1,
      )
      let newData = refundClothesList.filter((item) => !item.refundReason)
      if (newData.length > 0) {
        this.$message.error('请填写退款原因')
        return
      }
      this.$confirm('确定退款吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.orderRefundLoading = true
          this.orderRefundLoadingVal = '退款中...'
          let orderNumber = this.orderNumber
          let refundClothesList = this.checkArr.filter(
            (item) => this.checkedClothesList.indexOf(item.clothesNum) !== -1,
          )
          let data = {
            orderNumber,
            refundClothesList,
          }
          console.log(data)
          orderRefund(data).then((response) => {
            if (response.data.code === 0) {
              this.refundUserName = response.data.data
              this.showModal = false
              this.$message.success('退款成功')
              this.currentPage = 1
              this.orderArr = []
              this.loadOrderArr()
            } else {
              this.$message.error(response.data.msg)
            }
            this.orderRefundLoading = false
          })
        })
        .catch(() => {
          this.orderRefundLoading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.reserveView {
   padding: 20px;
   overflow: auto;
  > .headerView {
    display: flex;
    padding-bottom: 5px;
    line-height: 40px;
    overflow: hidden;
    z-index: 100;
    font-size: 14px;
    color: #999;
    text-align: center;

    > .headerItem {
      flex: 1;
    }
    
  }

  > .listView {
    // padding: 150px 30px 30px 30px;
    // min-height: 300px;
    overflow-x: auto;
    overflow-y: auto;

    > .listItem {
      position: relative;
      display: flex;
      font-size: 14px;
      background: #fff;
      text-align: center;
      padding: 15px 0 15px 0;
      line-height: 20px;
      align-items: center;
      color: #333;
      cursor: pointer;

      > div {
        flex: 1;
      }
    }

    > .listItem:nth-child(odd) {
      background: #f8f8f8;
    }

    > .moreItem {
      margin-top: 5px;
      line-height: 40px;
      font-size: 13px;
      color: #3370FF;
      text-align: center;
    }
  }
}

#refundViewPopView > .popView-contentView > .contentView {
  padding: 0 15px;

  > .topView {
    background: #fff;
    padding: 10px 0;
    display: flex;

    > div {
      width: 145px;
      margin-right: 20px;
    }

    > div:nth-child(1) {
      margin-left: 30px;
    }
  }

  > .headerView {
    display: flex;
    text-align: center;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    color: #999;

    > div {
      flex: 1;
    }

    > div:nth-child(1) {
      flex: 0.5;
    }
  }

  > .listView {
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    padding-bottom: 25px;

    > .listItem {
      position: relative;
      display: flex;
      padding: 10px 0;
      line-height: 30px;
      font-size: 14px;
      color: #333;
      background: #fff;

      > div {
        flex: 1;

        > .btn-select {
          width: 60px;
          height: 100%;
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_N.png)
            center / 28px no-repeat;
        }

        .btn-select.select {
          background: transparent
            url(https://static.bokao2o.com/wisdomDesk/images/Def_Icon_Select_S.png)
            center / 28px no-repeat;
        }
      }

      > div:nth-child(1) {
        flex: 0.5;
      }
    }
  }

  > .btnView {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>
